import React, {useEffect, useRef, useState} from "react";
import style from "./videoAudioControl.module.css";


const VideoAudioControl = (props) => {
  let vimeo;
  let buttonRef = useRef();
  // let audioStatus = 0;
  let [audioStatus, setAudioStatus] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      loadVimeo();

      if (!vimeo) {
        return;
      }

      let domNodes = buttonRef.current;
      const container = domNodes.parentElement;

      const iframe = container.querySelector('iframe');
      const player = new vimeo.Player(iframe);

      player.on('fullscreenchange', (event) => {
        if (document.fullscreenElement) {
          console.log(`Element: ${document.fullscreenElement.id} entered full-screen mode.`);
          console.log(audioStatus);

          player.setVolume(1);
        } else {
          console.log('Leaving full-screen mode.');
          console.log(audioStatus);
          player.setVolume(audioStatus);
          setAudioStatus(0);
        }
      });
    },1000);
  }, []);

  const loadVimeo = () => {
    if (vimeo) {
      return;
    }

    if (typeof window !== 'undefined' && typeof window.Vimeo !== 'undefined') {
      try {
        vimeo = window.Vimeo;
      } catch (e) {
        console.error('VIMEO', e);
      }
    } else {
      console.log("Vimeo isn't loaded");
    }
  };

  const toggleVideoSound = event => {
    loadVimeo();

    if (!vimeo) {
      return;
    }

    const container = event.target.parentElement;
    const iframe = container.querySelector('iframe');
    const player = new vimeo.Player(iframe);

    const volume = !audioStatus * 1;
    setAudioStatus(volume);
    player.setVolume(volume);
  };

  return (
    <button ref={buttonRef} className={[style.button, "fas", audioStatus ? "fa-volume-up" : "fa-volume-off"].join(' ')} onClick={toggleVideoSound}></button>
  )
};

export default VideoAudioControl;

import React from "react";
import Img from "gatsby-image";
import {Link} from "gatsby";
import styles from "./team-carousel.module.css";
import {Row, Col} from 'react-bootstrap';


const TeamCarousel = ({photo, name, position, description, website}) => {

  return (
    <div>
      <Row className={styles.resourcesContent}>
        <Col className={styles.imageContent}>
          <Img fluid={photo} />
          <Link to={'/mom-team/'}>
            <div className={styles.name} >
              <h6>{name}</h6>
              {name === "Aimee Cruz" &&
                <p className={styles.position}>{position}</p>
              }
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  )
};

export default TeamCarousel



import {graphql, useStaticQuery, Link} from "gatsby";
import Img from "gatsby-image";
import React from "react";
import {Col, Row} from "react-bootstrap";

const AsSeenOn = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "as-seen-on/fact-1-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 830) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
    <Row className="justify-content-center mt-5">
      <Col sm={9} style={{"marginBottom": "14px", "paddingLeft": "0px"}}>
        <h3>As seen on...</h3>
      </Col>
    </Row>
    <Row className="justify-content-left mb-5">
      <Col xs={12} sm={{ span: 8, offset: 2 }}>
        <Link to='/press'>
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </Link>
      </Col>
    </Row>
    </>
  )
};

export default AsSeenOn

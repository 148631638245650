import React, {useState} from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import {graphql} from "gatsby"
import {Row, Col, Card, Button, CardDeck} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import "./index.css";
import Img from "gatsby-image";
import AsSeenOn from "../components/as-seen-on";
import styles from "./index.module.css";
import Timer from "../components/timer";
import Carousel from 'react-multi-carousel';
import TeamCarousel from "../components/team-carousel";
import VideoAudioControl from "../components/videoAudioControl";
import env from "../config/env";

const IndexPage = ({data}) => {
  const [isVideoVisible, setShowVideo] = useState(true);

  //get nextGiveaway date for countDown
  const currentDate = new Date();
  const dueDay = currentDate.getDate() < 15 ? '15' : '01';
  let dueMonth = dueDay === '15' ? currentDate.getMonth()+1 : currentDate.getMonth()+2;
  dueMonth = dueMonth < 10 ? `0${dueMonth}` : dueMonth;
  let nextGiveaway = [currentDate.getFullYear(), dueMonth, dueDay].join('-') + "T00:00:00.000" + "-07:00";

  const resourceImages = {};
  data.allFile.edges.map(item => {
    resourceImages[item.node.name] = item.node;
  });

  return (
    <Layout structuredData={{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Moms on Maternity",
      "legalName": "Moms on Maternity, Inc.",
      "url": "https://www.momsonmaternity.com",
      "foundingDate": "2017",
      "founders": [
        {
          "@type": "Person",
          "name": "Aimee M Cruz"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/MomsOnMaternity/",
        "https://www.youtube.com/channel/UCCAGR4adI21ao1Jp7CTY32g",
        "https://www.instagram.com/momsonmaternity/"
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "4403 Muir Ave",
        "addressLocality": "San Diego",
        "addressRegion": "CA",
        "postalCode": "92107",
        "addressCountry": "USA"
      },
      "logo": {
        "@type": "ImageObject",
        "url": env.domain + data.logo.publicURL
      }
    }}>

      <div>
        <Row>
          <Col md={9} sm={12} xs={12}>
            <div className="frontpageCover">
              <Row className="presentationContent">
                <Row className="presentationDescription">
                  <Col>
                    <h4>We have found that when you feel good, your family and work feels good too.</h4>
                    {/*<Button href={'/become-a-member'} className={[globalStyle.btnPrimary, globalStyle.submitButtonOrange, styles.redirectTo, 'joinUsButton'].join(' ')} role="button">JOIN US</Button>*/}
                  </Col>
                </Row>

                { isVideoVisible &&
                <Row className="presentationVideo">
                  {data.becomeAMomMember.edges.map(item => (
                    <Col key={item.node.id} id={item.node.id} xs={12} md={12} sm={12} className={styles.videoContent}>
                      <Row className='justify-content-left videoRow' >
                        <Col>
                          <iframe id={item.node.id}
                                  src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                                  width="100%"
                                  height="100%"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen"
                                  allowFullScreen
                                  title="Become a member"
                                  controls controlsList="nodownload">
                          </iframe>
                          <VideoAudioControl></VideoAudioControl>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
                }
              </Row>
            </div>

            {/*<Row className="joinUsButtonForMobileContent">*/}
            {/*  <Col>*/}
            {/*    <Button href={'/become-a-member'} className={[globalStyle.btnPrimary, globalStyle.submitButtonOrange, 'joinUsButtonForMobile'].join(' ')} role="button">JOIN US</Button>*/}
            {/*  </Col>*/}
            {/*</Row>*/}

            <Row className="text-center facilitiesBanner">
              <div className="col-md-12">
                <h2 className="facilitiesBannerPhoto">Browse our benefits that include Prenatal Yoga, Mommy and Me Yoga, Baby Massage, Mom Meditations, Discounts and more...  </h2>
                {/*<Link to="/become-a-member/" ><Img className="facilitiesBannerPhoto" fluid={data.facilitiesBanner.childImageSharp.fluid} /> </Link>*/}
              </div>
            </Row>

            <Row className="justify-content-center aboutFounder">
              <Col md={4}>
                <h2>Our story starts with...</h2>
                <p>a passion to support you. Our parenting community is here to build connection and provide support to parents everywhere.</p>
              </Col>
              <Col md={6}>
                { isVideoVisible &&
                <Row className="homepageVideo">
                  {data.vimeoMomsPresentationIntro.edges.map(item => (
                    <Col key={item.node.id} id={item.node.id} xs={12} md={12} sm={12} className={styles.videoContent}>
                      <iframe id={item.node.id}
                              src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                              width="100%"
                              height="100%"
                              frameBorder="0"
                              allow="autoplay; fullscreen"
                              allowFullScreen
                              title="Presentation Video"
                              controls controlsList="nodownload" >
                      </iframe>
                      <VideoAudioControl></VideoAudioControl>
                    </Col>
                  ))}
                </Row>
                }
                {/*<Row className="seeTeamButton">*/}
                {/*  <Col>*/}
                {/*    <Button href={'/become-a-member/'} className={[globalStyle.btnPrimary, globalStyle.redirectButtonPink, styles.redirectTo + ' justify-content-center']} role="button">More About MOM Membership</Button>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
              </Col>
            </Row>

            <Row className="eventsContent">
              <Col md={10} className="eventsVideoContent">
                <Row>
                  <Col xs={12} md={6} sm={12} className="eventsVideoColumn">
                    <Link className="redirectToEventsLink" to={"/community-events/"}>
                      <h3 className="homepageTitle sectionTitle sectionTitleForEvents">Community Events</h3>
                      <div className="overlay"></div>
                      <div className="eventVideo" >
                        <div className="contentOverVideo"></div>
                        {data.vimeoComunityEvents.edges.map(item => (
                          <iframe key={item.node.id} id={item.node.id} src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=0;byline=0;title=0;background=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                                  width="440"
                                  height="360"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen"
                                  allowFullScreen
                                  title="Community events"
                                  controls controlsList="nodownload">
                          </iframe>
                        ))}
                      </div>
                    </Link>
                  </Col>

                  <Col xs={12} md={6} sm={12} className="eventsVideoColumn">
                    <Link className="redirectToEventsLink" to={"/retreat-events/"}>
                      <h3 className="sectionTitle sectionTitleForEvents homepageTitle">Retreat Events</h3>

                      <div className="overlay"></div>

                      <div className="eventVideo" >
                        {data.vimeoRetreatEvents.edges.map(item => (
                          <iframe key={item.node.id} src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=0;byline=0;title=0;background=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                                  width="440"
                                  height="360"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen"
                                  allowFullScreen
                                  title="Community events"
                                  controls controlsList="nodownload">
                          </iframe>
                        ))}
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-center homePageBlogContainer">
              <Col md={10}>
                <Row className="justify-content-center">
                  <div className="col-md-3" className="recentPostsTitle">
                    <Link to="/blog" className={[globalStyle.btnPrimary, globalStyle.submitButtonOrange]}>
                      <h3 className="sectionTitle homepageTitle">Recent Posts</h3>
                    </Link>
                  </div>
                </Row>

                <CardDeck>
                  {data.allMarkdownRemark.edges.map(({node}) => (
                    <Card key={node.id}>
                      <Link id="hoverShowImage" to={node.frontmatter.path}>
                        <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid}/>
                      </Link>

                      <Card.Body>
                        <Card.Title className="blogTitle"><Link to={node.frontmatter.path}>{node.frontmatter.title}</Link></Card.Title>
                        <Card.Text className="cardText">
                          {node.rawMarkdownBody.replace(/#/g, '').substring(0, 200)}...
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <Link className={globalStyle.redirectLink} to={node.frontmatter.path}>Read More »</Link>
                      </Card.Footer>
                    </Card>
                  ))}
                </CardDeck>
              </Col>
            </Row>

            <Row className="carouselContent">
              <Col>
                <h3 className="momTitle sectionTitle homepageTitle">MOM Team</h3>
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={2000}
                  autoPlay={true}
                  centerMode={false}
                  className=""
                  containerClass="container-with-dots"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024
                      },
                      items: 3,
                      partialVisibilityGutter: 40
                    },
                    mobile: {
                      breakpoint: {
                        max: 464,
                        min: 0
                      },
                      items: 1,
                      partialVisibilityGutter: 30
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 464
                      },
                      items: 2,
                      partialVisibilityGutter: 30
                    }
                  }}
                  showDots={true}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {data.momTeam.siteMetadata.momTeam.map(member => (
                    <div key={member.name}>
                      <TeamCarousel
                        photo={resourceImages[member.photo]['childImageSharp']['fluid']}
                        name={member.name}
                        position={member.position}>
                      </TeamCarousel>
                    </div>
                  ))}
                </Carousel>
              </Col>
            </Row>

            <Row className="asSeenOn">
              <Col>
                <AsSeenOn />
              </Col>
            </Row>
          </Col>

          <Col md={3} sm={12} xs={12}>
            <Row className="homepageRightSide">
              <Col className="nextworkshopContainer">

                <div className="nextworkshop">
                    <Link to={'/unimom-minuet'}>
                      <h1 className="title titleNextGiveaway">Unimom Breastpump</h1>
                      <h3 className="giveAwaySubtitle">Next Giveaway</h3>
                      <h3 className="giveAwaySubtitle giveAwayPrice">a $179 value</h3>
                      <Timer className="timerContent" endDate={nextGiveaway} />
                      <Img className="giveAwayBanner" style={{"marginBottom": "13rem"}} fluid={data.unimomBanner.childImageSharp.fluid}></Img>
                    </Link>
                </div>

                {/*<Row className="memberBanner">*/}
                {/*  <Col>*/}
                {/*    <Link to="/become-a-member/" >*/}
                {/*      <Img style={{borderRadius: "6px"}} fluid={data.memberBanner.childImageSharp.fluid} />*/}
                {/*    </Link>*/}
                {/*  </Col>*/}
                {/*</Row>*/}

                <Row className="justify-content-center clothesBanner">
                  {/*<div className="col-md-8">*/}
                  <Col>
                    {/*<Link to="/shop/clothes/" >*/}
                    {/*  <Img className="clothesBannerPhoto" fluid={data.momAdBannerPhoto.childImageSharp.fluid} />*/}
                    {/*</Link>*/}
                  </Col>
                  {/*</div>*/}
                </Row>

              </Col>
            </Row>

          </Col>
        </Row>
      </div>
    </Layout>
  )
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {posttype: {eq: "blogpost"}}},
      sort: {order: DESC, fields: frontmatter___date},
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          rawMarkdownBody
          frontmatter {
            path
            title
            date(formatString: "DD MMMM, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
    founderPhoto: file(relativePath: { eq: "founder/Aimee-Cruz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    frontpageCover: file(relativePath: { eq: "frontpage/jessica-rockowitz-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    momAdBannerPhoto: file(relativePath: { eq: "ads/mom-ad-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    memberBanner: file(relativePath: { eq: "ads/member-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    unimomBanner: file(relativePath: { eq: "ads/unimom-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    momTeam: site {
      siteMetadata {
        momTeam {
          name
          position
          description
          photo
        }    
      }
    }
    allFile(filter: {relativePath: {regex: "/team\/.*/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    vimeoVideos: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "member"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    vimeoMomsPresentationIntro: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "moms-presentation-intro"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    becomeAMomMember: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "become-a-mom-member"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    vimeoComunityEvents: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "community-events"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    vimeoRetreatEvents: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "retreat-events"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    communityThumbnail: file(relativePath: { eq: "mom-workshop/video-posters/community-thumbnail.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    retreatThumbnail: file(relativePath: { eq: "mom-workshop/video-posters/retreat-thumbnail.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    facilitiesBanner: file(relativePath: { eq: "ads/facilities-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "logo-new.png" }) {
        publicURL
    }
  }
`;

export default IndexPage
//
// import React, {useState, useEffect} from "react"
// import {Link} from "gatsby"
// import Layout from "../components/layout"
// import {graphql} from "gatsby"
// import {Row, Col, Button, Form, Alert} from "react-bootstrap";
// import homepageStyle from "./index.module.css";
// import Img from "gatsby-image";
// import formStyle from "./form.module.css";
// import states from "../config/usa-states-and-cities";
// import addToMailchimp from "gatsby-plugin-mailchimp";
// import env from "../config/env";
// import Carousel from "react-multi-carousel";
// import TeamCarousel from "../components/team-carousel";
// import styles from "../components/footer.module.css";
// import globalStyle from "../styles/global.module.css";
//
// import HomeCard from "../components/homeCard"
//
// const Index = ({data}) => {
//   const resourceImages = {};
//
//   data.teamImages.edges.map(item => {
//     resourceImages[item.node.name] = item.node;
//   });
//
//   const [usState, setUSState] = useState('');
//   const [cities, setCities] = useState([]);
//   const [showZoomInviteMessage, setShowZoomInviteMessage] = useState({ show: false, message: "" });
//   const [showZoomInviteStateOfAffairsErrorMessage, setShowZoomInviteStateOfAffairsErrorMessage] = useState({ show: false, message: "" });
//   const [showZoomInviteMessageErrorMessage, setShowZoomInviteMessageErrorMessage] = useState({ show: false, message: "" });
//   const [showZoomInviteNameErrorMessage, setShowZoomInviteNameErrorMessage] = useState({ show: false, message: "" });
//   const [showZoomInviteEmailErrorMessage, setShowZoomInviteEmailErrorMessage] = useState({ show: false, message: "" });
//   const [showZoomInvitePhoneCallErrorMessage, setShowZoomInvitePhoneCallErrorMessage] = useState({ show: false, message: "" });
//   const [showZoomInvitePhoneErrorMessage, setShowZoomInvitePhoneErrorMessage] = useState({ show: false, message: "" });
//   const [showZoomInviteStateErrorMessage, setShowZoomInviteStateErrorMessage] = useState({ show: false, message: "" });
//   const [showZoomInviteCityErrorMessage, setShowZoomInviteCityErrorMessage] = useState({ show: false, message: "" });
//   const [formResult, setFormResult] = useState({});
//   const [showAlert, setShowAlert] = useState({show: false, msg: ""});
//   const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
//
//   const [zoomInviteFormState, setZoomInviteFormValues] = useState({
//     zoomInviteStateOfAffairs: "",
//     zoomInviteMessage: "",
//     zoomInviteName: "",
//     zoomInviteEmail: "",
//     zoomInvitePhone: "",
//     zoomInvitePhoneCall: "",
//     zoomInviteState: "",
//     zoomInviteCity: "",
//   });
//
//   const showZoomInviteMessageRow = () => {
//     if(zoomInviteFormState.zoomInviteStateOfAffairs === "Neither: Explain") {
//       setShowZoomInviteMessage({show: true});
//     } else {
//       setShowZoomInviteMessage({show: false});
//     }
//   }
//
//   useEffect(() => {
//     populateCities();
//   }, [usState]);
//
//   const populateCities = () => {
//     if (!usState) {
//       setCities([]);
//       return;
//     }
//     const cities = [];
//     for (const city of states[usState]) {
//       cities.push(city)
//     }
//     cities.sort()
//     setCities(cities);
//   }
//
//   const selectUSState = (event) => {
//     setZoomInviteFormValues({
//       ...zoomInviteFormState,
//       zoomInviteCity: ''
//     })
//     setUSState(event.target.value);
//     setZoomInviteFormValues({
//       ...zoomInviteFormState,
//       zoomInviteState: event.target.value
//     })
//     setUSState(event.target.value);
//   }
//
//   const selectCity = (event) => {
//     setZoomInviteFormValues({
//       ...zoomInviteFormState,
//       zoomInviteCity: event.target.value
//     })
//   }
//
//   const handleZoomInviteFormChange = e => {
//     let value = e.target.value;
//     setZoomInviteFormValues({
//       ...zoomInviteFormState,
//       [e.target.name]: value,
//     });
//   };
//
//   const validateForm = () => {
//     let isValid = true;
//
//     if (!zoomInviteFormState.zoomInviteStateOfAffairs) {
//       isValid = false;
//
//       setShowZoomInviteStateOfAffairsErrorMessage({
//         show: true,
//         message: "Please choose your status!"
//       })
//     }
//     if (zoomInviteFormState.zoomInviteStateOfAffairs === "Neither: Explain" && !zoomInviteFormState.zoomInviteMessage) {
//       isValid = false;
//       setShowZoomInviteMessageErrorMessage({
//         show: true,
//         message: "Please explain!"
//       })
//     }
//     if (!zoomInviteFormState.zoomInviteName) {
//       isValid = false;
//       setShowZoomInviteNameErrorMessage({
//         show: true,
//         message: "Please enter your name!"
//       })
//     }
//     if (!zoomInviteFormState.zoomInviteEmail) {
//       isValid = false;
//       setShowZoomInviteEmailErrorMessage({
//         show: true,
//         message: "Please enter your email!"
//       })
//     }
//
//     if (zoomInviteFormState.zoomInvitePhoneCall === "Yes" && !zoomInviteFormState.zoomInvitePhone) {
//       isValid = false;
//       setShowZoomInvitePhoneErrorMessage({
//         show: true,
//         message: "Please add your phone number!"
//       })
//     }
//
//     if (!zoomInviteFormState.zoomInviteState) {
//       isValid = false;
//       setShowZoomInviteStateErrorMessage({
//         show: true,
//         message: "Please choose your state!"
//       })
//     }
//
//     if (!zoomInviteFormState.zoomInviteCity) {
//       isValid = false;
//       setShowZoomInviteCityErrorMessage({
//         show: true,
//         message: "Please choose your city!"
//       })
//     }
//
//     return isValid
//   };
//
//   const saveToMailchimp = async () => {
//     try {
//       const result = await addToMailchimp(zoomInviteFormState.zoomInviteEmail, {
//           STATEOFAFF: zoomInviteFormState.zoomInviteStateOfAffairs,
//           MESSAGE: zoomInviteFormState.zoomInviteMessage,
//           NAME: zoomInviteFormState.zoomInviteName,
//           EMAIL: zoomInviteFormState.zoomInviteEmail,
//           PHONE: zoomInviteFormState.zoomInvitePhone,
//           PHONECALL: zoomInviteFormState.zoomInvitePhoneCall,
//           STATE: zoomInviteFormState.zoomInviteState,
//           CITY: zoomInviteFormState.zoomInviteCity
//         }, "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=1b24a76b18"
//       );
//
//       const alreadyAMemberError = zoomInviteFormState.zoomInviteEmail + ` is already subscribed to list`;
//       setFormResult(result);
//       if (result.result === 'error' && result.msg.includes(alreadyAMemberError)) {
//         setShowAlert({
//           show: true,
//           msg: 'You already subscribed to list!'
//         });
//       } else {
//         setShowAlert({
//           show: true,
//           msg: result.msg
//         });
//       }
//
//     } catch (err) {
//       console.error(err);
//     }
//     return true;
//   };
//
//   const validateAndSaveZoomInviteFormData = async () => {
//     if (validateForm()) {
//       await saveToMailchimp();
//     }
//   }
//
//   return (
//     <Layout
//       disableLayoutPadding={true}
//       structuredData={{
//         "@context": "https://schema.org",
//         "@type": "Organization",
//         "name": "Moms on Maternity",
//         "legalName": "Moms on Maternity, Inc.",
//         "url": "https://www.momsonmaternity.com",
//         "foundingDate": "2017",
//         "founders": [
//           {
//             "@type": "Person",
//             "name": "Aimee M Cruz"
//           }
//         ],
//         "sameAs": [
//           "https://www.facebook.com/MomsOnMaternity/",
//           "https://www.youtube.com/channel/UCCAGR4adI21ao1Jp7CTY32g",
//           "https://www.instagram.com/momsonmaternity/"
//         ],
//         "address": {
//           "@type": "PostalAddress",
//           "streetAddress": "4403 Muir Ave",
//           "addressLocality": "San Diego",
//           "addressRegion": "CA",
//           "postalCode": "92107",
//           "addressCountry": "USA"
//         },
//         "logo": {
//           "@type": "ImageObject",
//           "url": env.domain + data.logo.publicURL
//         }
//       }}>
//
//       <Row>
//         <Col>
//           {/*<Row>*/}
//           {/*  <Col className={homepageStyle.coverPhotoImage}>*/}
//           {/*    <Img className={homepageStyle.coverPhoto} fluid={data.frontpageCover.childImageSharp.fluid}></Img>*/}
//           {/*    <div className={homepageStyle.textBlock}>*/}
//           {/*      <p className={homepageStyle.title}>Calling All Expecting and New Parents!</p>*/}
//           {/*    </div>*/}
//           {/*    <div className={homepageStyle.actionButtonContent}>*/}
//           {/*      <a*/}
//           {/*        type="button"*/}
//           {/*        href="#zoomTitle"*/}
//           {/*        className={[ homepageStyle.actionButton ]}*/}
//           {/*      >*/}
//           {/*        Get Your Invite*/}
//           {/*      </a>*/}
//           {/*    </div>*/}
//           {/*  </Col>*/}
//           {/*</Row>*/}
//
//
//           <Row className={homepageStyle.frontpageAdRow}>
//             <Col className={homepageStyle.frontpageAdCol}>
//               <a
//                 href="https://www.eventbrite.com/e/ashley-jacobs-dreams-do-come-true-moms-retreat-in-valle-de-guadalupe-tickets-274485081267?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=escb"
//               >
//                 <Img className={homepageStyle.frontpageAdImage} fluid={data.frontpageAd.childImageSharp.fluid}></Img>
//               </a>
//             </Col>
//           </Row>
//
//           <Row>
//             <Col>
//               <h3 className={[globalStyle.entryTitle, homepageStyle.entryTitleHomepage].join(" ")}>Our passion is connecting and hosting new and expecting parents!</h3>
//             </Col>
//           </Row>
//
//           <Row className="justify-content-md-center">
//
//             <HomeCard
//               href={'/labor-book'}
//               styles={homepageStyle}
//               fluid={data.laborBookPhoto.childImageSharp.fluid}
//               buttonText={"The Book"} />
//
//             <HomeCard
//               href={"/mommys-helper"}
//               styles={homepageStyle}
//               fluid={data.mommyshelperPhoto.childImageSharp.fluid}
//               buttonText={"Mommy's Helper"} />
//
//             <HomeCard
//               href={"https://www.tummytimeclub.com"}
//               styles={homepageStyle}
//               fluid={data.tummytimePhoto.childImageSharp.fluid}
//               buttonText={"The Club"} />
//
//           </Row>
//
//           <Row className={[homepageStyle.secondLine, "justify-content-md-center"].join(' ')}>
//             <HomeCard
//               href={"https://podcasts.apple.com/us/podcast/the-moms-on-maternity-podcast-with-aimee-cruz/id1556912487"}
//               styles={homepageStyle}
//               fluid={data.podcastPhoto.childImageSharp.fluid}
//               buttonText={"Podcasts"} />
//
//             {data.vlogs.edges.map(({node}) => (
//               <HomeCard
//                 href={node.frontmatter.path}
//                 styles={homepageStyle}
//                 fluid={node.frontmatter.squaredImage.childImageSharp.fluid}
//                 buttonText={"Vlogs"} />
//             ))}
//
//             {data.allMarkdownRemark.edges.map(({node}) => (
//               <HomeCard
//                 href={node.frontmatter.path}
//                 styles={homepageStyle}
//                 fluid={node.frontmatter.squaredImage.childImageSharp.fluid}
//                 buttonText={"Blogs"} />
//             ))}
//
//           </Row>
//
//           {/*<Row className={homepageStyle.zoomTitle}>*/}
//           {/*  <Col>*/}
//           {/*    <h4>Upcoming Event</h4>*/}
//           {/*  </Col>*/}
//           {/*</Row>*/}
//
//           {/*<Row>*/}
//           {/*  <Col md={8} sm={12} xs={12} className={"offset-md-2"}>*/}
//           {/*    <a href="https://www.eventbrite.com/e/mindfulness-yoga-and-creativity-for-families-tickets-164783043519"*/}
//           {/*       target="_blank">*/}
//           {/*      <Img fluid={data.mindfulnessUpcomingEventPhoto.childImageSharp.fluid}/>*/}
//           {/*    </a>*/}
//
//           {/*  </Col>*/}
//           {/*</Row>*/}
//
//           {/*<Row className={"justify-content-md-center"}>*/}
//           {/*  <Col md={8} sm={12} xs={12} className={homepageStyle.eventButtonContent}>*/}
//           {/*    <Link id="hoverShowImage"*/}
//           {/*          to="https://www.eventbrite.com/e/mindfulness-yoga-and-creativity-for-families-tickets-164783043519"*/}
//           {/*          target="_blank">*/}
//           {/*      <Button type="button"*/}
//           {/*              className={[*/}
//           {/*                homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
//           {/*              ]}*/}
//           {/*      >*/}
//           {/*        Event Tickets*/}
//           {/*      </Button>*/}
//           {/*    </Link>*/}
//           {/*  </Col>*/}
//           {/*</Row>*/}
//
//           <Row className={homepageStyle.zoomTitle} id="zoomTitle" style={{height: "2rem"}}></Row>
//
//           <Row className={homepageStyle.zoomTitle}>
//             <Col>
//               <h4>Get Your Discovery Invite Now!</h4>
//             </Col>
//           </Row>
//
//           <Row className={homepageStyle.zoomSubtitle} id="zoomSubtitle">
//             <Col>
//               <p>Join Aimee Cruz, Founder and CEO, of Moms on Maternity each month. Aimee invites the experts to
//                 share and more importantly this is a free, fun and safe space for you to connect with others who are
//                 pregnant or with a baby at home. Share what is on Your Mind.</p>
//             </Col>
//           </Row>
//
//           <Row className={homepageStyle.zoomFormRow} >
//             <Col md={4} sm={12} xs={12} className={"offset-md-1"}>
//               <Form onSubmit={handleZoomInviteFormChange} >
//
//                 <Form.Row>
//                   <Col>
//                     <Form.Group as={Col} controlId="zoomInviteStateOfAffairs" className={homepageStyle.registerFormGroup}>
//                       <Form.Label className={[formStyle.formLabel, formStyle.required]}>Are You Currently Pregnant Or With A Baby At Home?</Form.Label>
//                       <Form.Control as="select"
//                                     onChange={(e) => {handleZoomInviteFormChange(e);
//                                       setShowZoomInviteStateOfAffairsErrorMessage({ show: false });
//                                       showZoomInviteMessageRow()}}
//                                     value={zoomInviteFormState.zoomInviteStateOfAffairs}
//                                     placeholder="Are You Currently Pregnant Or With A Baby At Home?"
//                                     name="zoomInviteStateOfAffairs">
//                         <option className="defaultValue" value=""></option>
//                         <option>Pregnant</option>
//                         <option>Baby at home</option>
//                         <option>Neither: Explain</option>
//                       </Form.Control>
//                     </Form.Group>
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row>
//                   <Col xs={12} md={12} sm={12}>
//                     {showZoomInviteStateOfAffairsErrorMessage.show && (
//                       <Alert
//                         variant="danger"
//                         onClose={() => setShowZoomInviteStateOfAffairsErrorMessage({ show: false })}
//                         dismissible
//                       >
//                         {showZoomInviteStateOfAffairsErrorMessage.message}
//                       </Alert>
//                     )}
//                   </Col>
//                 </Form.Row>
//
//                 {
//                   zoomInviteFormState.zoomInviteStateOfAffairs === "Neither: Explain" &&
//                   <Form.Row className={["justify-content-md-left"].join(" ")}>
//                     <Col>
//                       <Form.Group as={Col} controlId="zoomInviteMessage" name="zoomInviteMessage"
//                                   className={[homepageStyle.registerFormGroup, homepageStyle.formLabel].join(' ')}>
//                         <Form.Label className={[formStyle.formLabel, formStyle.required]}>Message</Form.Label>
//                         <Form.Control
//                           className={homepageStyle.formInput}
//                           size="sm"
//                           value={zoomInviteFormState.zoomInviteMessage}
//                           type="text"
//                           name="zoomInviteMessage"
//                           placeholder="Select Your Status"
//                           onChange={(e) => {handleZoomInviteFormChange(e);
//                             setShowZoomInviteMessageErrorMessage({ show: false })}}
//                         />
//                       </Form.Group>
//                     </Col>
//                   </Form.Row>
//                 }
//
//                 <Form.Row>
//                   <Col xs={12} md={12} sm={12}>
//                     {showZoomInviteMessageErrorMessage.show && (
//                       <Alert
//                         variant="danger"
//                         onClose={() => setShowZoomInviteMessageErrorMessage({ show: false })}
//                         dismissible
//                       >
//                         {showZoomInviteMessageErrorMessage.message}
//                       </Alert>
//                     )}
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row className={["justify-content-md-left"].join(" ")}>
//                   <Col>
//                     <Form.Group as={Col} controlId="zoomInviteName" name="contactFirstName"
//                                 className={[homepageStyle.registerFormGroup, homepageStyle.formLabel].join(' ')}>
//                       <Form.Label className={[formStyle.formLabel, formStyle.required]}>Your Name</Form.Label>
//                       <Form.Control
//                         size="sm"
//                         type="text"
//                         name="zoomInviteName"
//                         placeholder="Full name here"
//                         value={zoomInviteFormState.zoomInviteName}
//                         onChange={(e) => {handleZoomInviteFormChange(e); setShowZoomInviteNameErrorMessage({ show: false })}}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row>
//                   <Col xs={12} md={12} sm={12}>
//                     {showZoomInviteNameErrorMessage.show && (
//                       <Alert
//                         variant="danger"
//                         onClose={() => setShowZoomInviteNameErrorMessage({ show: false })}
//                         dismissible
//                       >
//                         {showZoomInviteNameErrorMessage.message}
//                       </Alert>
//                     )}
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row className={[homepageStyle.rowStyle, "justify-content-md-left"].join(" ")}>
//                   <Col xs={12} sm={12} md={12}>
//                     <Form.Group as={Col} controlId="zoomInviteEmail" name="zoomInviteEmail"
//                                 className={[homepageStyle.registerFormGroup, homepageStyle.formLabel].join(' ')}>
//                       <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email address</Form.Label>
//                       <Form.Control
//                         className={homepageStyle.formInput}
//                         size="sm"
//                         value={zoomInviteFormState.zoomInviteEmail}
//                         type="email"
//                         name="zoomInviteEmail"
//                         placeholder="Type your email"
//                         onChange={(e) => {handleZoomInviteFormChange(e); setShowZoomInviteEmailErrorMessage({ show: false })}}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row>
//                   <Col xs={12} md={12} sm={12}>
//                     {showZoomInviteEmailErrorMessage.show && (
//                       <Alert
//                         variant="danger"
//                         onClose={() => setShowZoomInviteEmailErrorMessage({ show: false })}
//                         dismissible
//                       >
//                         {showZoomInviteEmailErrorMessage.message}
//                       </Alert>
//                     )}
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Group as={Col} controlId="zoomInvitePhone" name="zoomInvitePhone"
//                             className={[homepageStyle.registerFormGroup, homepageStyle.formLabel].join(' ')}>
//                   <Form.Label className={[formStyle.formLabel, zoomInviteFormState.zoomInvitePhoneCall === "Yes" ? formStyle.required : '']}>Phone Number</Form.Label>
//                   <Form.Control
//                     className={homepageStyle.formInput}
//                     size="sm"
//                     value={zoomInviteFormState.zoomInvitePhone}
//                     type="text"
//                     name="zoomInvitePhone"
//                     placeholder="Type your phone number"
//                     onChange={(e) => {handleZoomInviteFormChange(e); setShowZoomInvitePhoneErrorMessage({ show: false })}}
//                   />
//                 </Form.Group>
//
//                 <Form.Row>
//                   <Col xs={12} md={12} sm={12}>
//                     {showZoomInvitePhoneErrorMessage.show && (
//                       <Alert
//                         variant="danger"
//                         onClose={() => setShowZoomInvitePhoneErrorMessage({ show: false })}
//                         dismissible
//                       >
//                         {showZoomInvitePhoneErrorMessage.message}
//                       </Alert>
//                     )}
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row>
//                   <Col>
//                     <Form.Group as={Col} controlId="zoomInviteStateOfAffairs" className={homepageStyle.registerFormGroup}>
//                       <Form.Label className={[formStyle.formLabel, formStyle.required]}>Would you like a complementary
//                         30 minute phone call from a certified new parent educator?</Form.Label>
//                       <Form.Control as="select"
//                                     onChange={(e) => {handleZoomInviteFormChange(e);
//                                       setShowZoomInvitePhoneCallErrorMessage({ show: false })}}
//                                     value={zoomInviteFormState.zoomInvitePhoneCall}
//                                     placeholder="Yes/no"
//                                     name="zoomInvitePhoneCall">
//                         <option className="defaultValue" value="">Yes/No</option>
//                         <option>Yes</option>
//                         <option>No</option>
//                       </Form.Control>
//                     </Form.Group>
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row>
//                   <Col xs={12} md={12} sm={12}>
//                     {showZoomInvitePhoneCallErrorMessage.show && (
//                       <Alert
//                         variant="danger"
//                         onClose={() => setShowZoomInvitePhoneCallErrorMessage({ show: false })}
//                         dismissible
//                       >
//                         {showZoomInvitePhoneCallErrorMessage.message}
//                       </Alert>
//                     )}
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row>
//                   <Col md={12} sm={12} xs={12}>
//                     <Form.Group as={Col} controlId="zoomInviteState" className={homepageStyle.registerFormGroup}>
//                       <Form.Label className={[formStyle.formLabel, formStyle.required]}>State</Form.Label>
//                       <Form.Control as="select"
//                                     onChange={(e) => {selectUSState(e);
//                                       setShowZoomInviteStateErrorMessage({ show: false })}}
//                                     name="zoomInviteState">
//                         <option value="">Select Your State</option>
//                         {
//                           Object.keys(states).map(state => (
//                               <option style={{"background": "#5cb85c", "color": "#fff"}} key={state} value={state}>{state}</option>
//                             )
//                           )}
//                       </Form.Control>
//                     </Form.Group>
//                   </Col>
//
//                   <Col md={12} sm={12} xs={12}>
//                     <Form.Group as={Col} controlId="zoomInviteCity" className={homepageStyle.registerFormGroup}>
//                       <Form.Label className={[formStyle.formLabel, formStyle.required]}>City</Form.Label>
//                       <Form.Control as="select"
//                                     onChange={(e) => {selectCity(e);
//                                       setShowZoomInviteCityErrorMessage({ show: false })}}
//                                     name="zoomInviteCity">
//                         <option value="">Select Your City</option>
//                         {cities.map(city => (
//                           <option key={city} value={city}>{city}</option>
//                         ))}
//                       </Form.Control>
//                     </Form.Group>
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row>
//                   <Col xs={12} md={12} sm={12}>
//                     {showZoomInviteStateErrorMessage.show && (
//                       <Alert
//                         variant="danger"
//                         onClose={() => setShowZoomInviteStateErrorMessage({ show: false })}
//                         dismissible
//                       >
//                         {showZoomInviteStateErrorMessage.message}
//                       </Alert>
//                     )}
//                   </Col>
//                 </Form.Row>
//
//                 <Form.Row>
//                   <Col xs={12} md={12} sm={12}>
//                     {showZoomInviteCityErrorMessage.show && (
//                       <Alert
//                         variant="danger"
//                         onClose={() => setShowZoomInviteCityErrorMessage({ show: false })}
//                         dismissible
//                       >
//                         {showZoomInviteCityErrorMessage.message}
//                       </Alert>
//                     )}
//                   </Col>
//                 </Form.Row>
//
//                 <Button type="button"
//                         className={[
//                           homepageStyle.submitButton, homepageStyle.homepageButton
//                         ]}
//                         onClick={validateAndSaveZoomInviteFormData}>
//                   Submit request
//                 </Button>
//               </Form>
//
//               <Row>
//                 <Col xs={12} sm={8} className={styles.alertMessage}>
//                   {
//                     showAlert.show && formResult.result === 'error' &&
//                     <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>{showAlert.msg}</Alert>
//                   }
//                   {
//                     showAlert.show && formResult.result === 'success' &&
//                     <Alert variant="success" className={homepageStyle.succesAlert} onClose={() => setShowAlert(false)} dismissible>{showAlert.msg}</Alert>
//                   }
//                   {
//                     showError.show &&
//                     <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
//                     >{showError.onField} field should not be empty</Alert>
//                   }
//                 </Col>
//               </Row>
//
//               <Row className={homepageStyle.noteContainer}>
//                 <Col>
//                   <p className={homepageStyle.note}>Note:</p>
//                   <span className={homepageStyle.noteMessage}>
//                       In order to ensure the quality of each meeting we review all requests. Please look for your
//                       invitation within 24-48 hours.<br />
//                       Thank you and Congratulations!
//                     </span>
//                 </Col>
//               </Row>
//             </Col>
//
//             <Col md={6} sm={12} xs={12} className={"offset-md-0"}>
//               <Img fluid={data.frontpageBabyPhoto.childImageSharp.fluid}/>
//             </Col>
//           </Row>
//
//           <Row className={homepageStyle.babyAndToddlerPhotoSection}>
//             <Col>
//               <Row className={[homepageStyle.secondTitle]}>
//                 <Col>
//                   <h4 className={homepageStyle.blackZoomTitle}>Baby and Toddler Virtual Photo Fun</h4>
//                 </Col>
//               </Row>
//
//               <Row className={homepageStyle.zoomSubtitle}>
//                 <Col>
//                   <p>We know one thing we all have in common – taking photos of our babies!
//                     Let us transform one of yours into a fun and fine portrait!</p>
//                 </Col>
//               </Row>
//
//               <Row className={homepageStyle.babyAndToddlerButtonsSection}>
//                 <Col md={5} sm={5} xs={12} className={"offset-md-1"}>
//                   <Img fluid={data.oliviaFramedPhoto.childImageSharp.fluid}/>
//                   <Link id="hoverShowImage" to="/baby-portraits?type=holiday">
//                     <Button type="button"
//                             className={[
//                               homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton
//                             ]}
//                     >
//                       Holiday Portraits
//                     </Button>
//                   </Link>
//                 </Col>
//                 <Col md={5} sm={5} xs={12} className={"offset-md-0"}>
//                   <Img fluid={data.lucasFramedPhoto.childImageSharp.fluid}/>
//                   <Link id="hoverShowImage" to="/baby-portraits?type=classic">
//                     <Button type="button"
//                             className={[
//                               homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton
//                             ]}>
//                       Classic Portraits
//                     </Button>
//                   </Link>
//                 </Col>
//               </Row>
//
//             </Col>
//           </Row>
//
//           <Row>
//             <Col>
//               {/*<Row className={[homepageStyle.secondTitle]}>*/}
//               {/*  <Col>*/}
//               {/*    <h4 className={homepageStyle.blackZoomTitle}>Newest Content</h4>*/}
//               {/*  </Col>*/}
//               {/*</Row>*/}
//
//               {/*<Row className="justify-content-md-center">*/}
//               {/*  {data.allMarkdownRemark.edges.map(({node}) => (*/}
//               {/*    <Col md={3} sm={12} xs={12} key={node.id}>*/}
//               {/*      <Link id="hoverShowImage" to={node.frontmatter.path} target="_blank" rel="noreferrer">*/}
//               {/*        <Img className={homepageStyle.postTypeImage} fluid={node.frontmatter.squaredImage.childImageSharp.fluid}/>*/}
//               {/*      </Link>*/}
//               {/*      <div className={homepageStyle.buttonContainer}>*/}
//               {/*        <Link id="hoverShowImage" to={node.frontmatter.path} target="_blank" rel="noreferrer">*/}
//               {/*          <Button type="button"*/}
//               {/*                  className={[*/}
//               {/*                    homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
//               {/*                  ]}*/}
//               {/*                  >*/}
//               {/*            Blogs*/}
//               {/*          </Button>*/}
//               {/*        </Link>*/}
//               {/*      </div>*/}
//               {/*    </Col>*/}
//               {/*  ))}*/}
//
//               {/*  {data.vlogs.edges.map(({node}) => (*/}
//               {/*    <Col md={3} sm={12} xs={12}  key={node.id}>*/}
//               {/*      <Link id="hoverShowImage" to={node.frontmatter.path} target="_blank" rel="noreferrer">*/}
//               {/*        <Img className={homepageStyle.postTypeImage} fluid={node.frontmatter.squaredImage.childImageSharp.fluid}/>*/}
//               {/*      </Link>*/}
//               {/*      <div className={homepageStyle.buttonContainer}>*/}
//               {/*        <Link id="hoverShowImage" to={node.frontmatter.path} target="_blank" rel="noreferrer">*/}
//               {/*          <Button type="button"*/}
//               {/*                  className={[*/}
//               {/*                    homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
//               {/*                  ]}>*/}
//               {/*            Vlogs*/}
//               {/*          </Button>*/}
//               {/*        </Link>*/}
//               {/*      </div>*/}
//               {/*    </Col>*/}
//               {/*  ))}*/}
//
//               {/*  <Col md={3} sm={12} xs={12} >*/}
//               {/*    <a id="hoverShowImage"*/}
//               {/*          href="https://podcasts.apple.com/us/podcast/the-moms-on-maternity-podcast-with-aimee-cruz/id1556912487"*/}
//               {/*          target="_blank" rel="noreferrer">*/}
//               {/*      <Img className={homepageStyle.postTypeImage} fluid={data.podcastPhoto.childImageSharp.fluid}/>*/}
//               {/*    </a>*/}
//               {/*    <div className={homepageStyle.buttonContainer}>*/}
//               {/*      <a id="hoverShowImage"*/}
//               {/*            href="https://podcasts.apple.com/us/podcast/the-moms-on-maternity-podcast-with-aimee-cruz/id1556912487"*/}
//               {/*            target="_blank" rel="noreferrer">*/}
//               {/*        <Button type="button"*/}
//               {/*                className={[*/}
//               {/*                  homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
//               {/*                ]}>*/}
//               {/*          Podcasts*/}
//               {/*        </Button>*/}
//               {/*      </a>*/}
//               {/*    </div>*/}
//               {/*  </Col>*/}
//               {/*</Row>*/}
//
//               <Row className={homepageStyle.aboutSection}>
//                 <Col md={5} sm={5} xs={12} className={"offset-md-1"}>
//                   <Img fluid={data.aboutUsPhoto.childImageSharp.fluid}/>
//                 </Col>
//                 <Col md={5} sm={5} xs={12} className={"offset-md-0"}>
//                   <Row className={[homepageStyle.secondTitle]}>
//                     <Col>
//                       <h4 className={homepageStyle.blackZoomTitle}>About Moms On Maternity</h4>
//                       <p className={homepageStyle.aboutStyle}>
//                         Moms on Maternity was started in 2017 when Aimee Sando
//                         Cruz was on Maternity Leave with her second son. Aimee
//                         wanted to meet more women planning to go back to work
//                         while raising a family. She believes we 100% can be Great
//                         Moms and Great Professionals. Moms on Maternity now hosts
//                         expecting and new parents via live and virtual events. We
//                         provide parenting content and inspiration that helps as our
//                         babies grow up – and as we ourselves continue to journey as
//                         women.
//                       </p>
//                       <h4 className={homepageStyle.quote}>“Nothing worth having comes easy.”</h4>
//                       <p className={homepageStyle.aboutStyle}>― Theodore Roosevelt</p>
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//
//           <Row className={[homepageStyle.carouselContent, "carouselContent"].join(' ')}>
//             <Col className="col-md-offset-1 col-md-offset-0">
//               <h4 className={homepageStyle.blackZoomTitle}>Our Team</h4>
//               <Carousel
//                 additionalTransfrom={0}
//                 arrows
//                 autoPlaySpeed={3000}
//                 autoPlay={true}
//                 centerMode={false}
//                 className=""
//                 containerClass="container-with-dots"
//                 dotListClass=""
//                 draggable
//                 focusOnSelect={false}
//                 infinite
//                 itemClass=""
//                 keyBoardControl
//                 minimumTouchDrag={80}
//                 renderButtonGroupOutside={false}
//                 renderDotsOutside={false}
//                 responsive={{
//                   desktop: {
//                     breakpoint: {
//                       max: 3000,
//                       min: 1024
//                     },
//                     items: 4,
//                     partialVisibilityGutter: 40
//                   },
//                   mobile: {
//                     breakpoint: {
//                       max: 464,
//                       min: 0
//                     },
//                     items: 1,
//                     partialVisibilityGutter: 30
//                   },
//                   tablet: {
//                     breakpoint: {
//                       max: 1024,
//                       min: 464
//                     },
//                     items: 2,
//                     partialVisibilityGutter: 30
//                   }
//                 }}
//                 showDots={false}
//                 sliderClass=""
//                 slidesToSlide={1}
//                 swipeable
//               >
//                 {data.momTeam.siteMetadata.momTeam.map(member => (
//                   <div key={member.name}>
//                     <TeamCarousel
//                       photo={resourceImages[member.photo]['childImageSharp']['fluid']}
//                       name={member.name}
//                       position={member.position}>
//                     </TeamCarousel>
//                   </div>
//                 ))}
//               </Carousel>
//             </Col>
//           </Row>
//
//         </Col>
//       </Row>
//
//     </Layout>
//   )
// };
//
// export const query = graphql`
//   query {
//     allMarkdownRemark(
//       filter: {frontmatter: {posttype: {eq: "blogpost"}}},
//       sort: {order: DESC, fields: frontmatter___date},
//       limit: 1
//     ) {
//       totalCount
//       edges {
//         node {
//           id
//           rawMarkdownBody
//           frontmatter {
//             path
//             title
//             date(formatString: "DD MMMM, YYYY")
//             featuredImage {
//               childImageSharp {
//                 fluid(maxWidth: 800) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//             squaredImage {
//               childImageSharp {
//                 fluid(maxWidth: 300, quality: 100) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//           excerpt
//         }
//       }
//     }
//      vlogs: allMarkdownRemark(
//       filter: {frontmatter: {posttype: {eq: "vlog"}}},
//       sort: {order: DESC, fields: frontmatter___date},
//       limit: 1
//     ) {
//       totalCount
//       edges {
//         node {
//           id
//           rawMarkdownBody
//           frontmatter {
//             path
//             title
//             date(formatString: "DD MMMM, YYYY")
//             featuredImage {
//               childImageSharp {
//                 fluid(maxWidth: 800) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//             squaredImage {
//             childImageSharp {
//                 fluid(maxWidth: 300, quality: 100) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//           excerpt
//         }
//       }
//     }
//     laborBookPhoto: file(relativePath: { eq: "labor-book-promo.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 300, quality: 100) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     tummytimePhoto: file(relativePath: { eq: "tummytime-promo.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 300, quality: 100) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     mommyshelperPhoto: file(relativePath: { eq: "mommys-helper-promo.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 300, quality: 100) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     podcastPhoto: file(relativePath: { eq: "markdown-podcasts/podcast.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 300, quality: 100) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     aboutUsPhoto: file(relativePath: { eq: "about-us.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1800) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     frontpageAd: file(relativePath: { eq: "frontpage/ash-jacobs-ad1.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1800) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     frontpageCover: file(relativePath: { eq: "frontpage/homepage-cover-photo.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1800) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     frontpageBabyPhoto: file(relativePath: { eq: "frontpage/baby-at-home.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1800) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     oliviaFramedPhoto: file(relativePath: { eq: "portrait-photos/frames/framed/olivia.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1800) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     lucasFramedPhoto: file(relativePath: { eq: "portrait-photos/frames/framed/lucas.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1800) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     momTeam: site {
//       siteMetadata {
//         momTeam {
//           name
//           position
//           photo
//         }
//       }
//     }
//     teamImages: allFile(filter: {relativePath: {regex: "/team\/.*/"}}) {
//       edges {
//         node {
//           childImageSharp {
//             fluid(maxWidth: 600) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//           name
//         }
//       }
//     }
//     logo: file(relativePath: { eq: "logo-new.png" }) {
//       publicURL
//     }
//     mindfulnessUpcomingEventPhoto: file(relativePath: { eq: "events/mindfulness-yoga-and-creativity-for-families-event.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1800) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;
//
// export default Index

